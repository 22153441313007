<template>
    <section id="about" class="events">
    <div class="container" data-aos="fade-up">
    <div class="row">
        
        <div class="col-md-12 d-flex align-items-stretch order-2 order-lg-1 content">
        <div class="card">
            <div class="card-img">
            <img src="/assets/img/banner1.png" alt="...">
            </div>
            <div class="card-body">
                <p>{{$t("intro-p-1")}}</p>
                <p>{{$t("intro-p-2")}}</p>
                <p><b>{{$t("intro-p-3")}}</b></p>
            </div>
        </div>
        </div>
    </div>
    </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>