<template>
  <section id="cta" class="cta">
      <div class="container">

        <div class="text-center" data-aos="zoom-in">
          <h3>{{$t("scope-of-awards")}}</h3>
          
        </div>

      </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.cta {

  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)), url("/assets/img/hero-bg8.png") fixed center center;
  
}
</style>