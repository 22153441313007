<template>
<section id="about" class="events">
      <div class="container" data-aos="fade-up">
        <div class="row">
          
          <div class="col-md-12 d-flex align-items-stretch order-2 order-lg-1 content">
            <div>
              <!-- <div class="card-img">
                <img src="/assets/img/banner1.png" alt="...">
              </div> -->
              <div class="card-body">
            <p>{{$t("scope-p-1")}}</p>
            <ul>
              <li><i class="bi bi-check-circle"></i> {{$t("stem-full")}}</li>
              <li><i class="bi bi-check-circle"></i> {{$t("agriculture")}}</li>
              <li><i class="bi bi-check-circle"></i> {{$t("health")}}</li>
              <li><i class="bi bi-check-circle"></i> {{$t("environment")}}</li>
              <li><i class="bi bi-check-circle"></i> {{$t("applied-social-sciences-education")}}</li>
            </ul>
            <p v-html="$t('scope-p-2')"></p>
            <ul>
                <ol><i class="bi bi-check-circle"></i> {{$t("scope-p-2-1")}}</ol>
                <ol><i class="bi bi-check-circle"></i> {{$t("scope-p-2-2")}}</ol>
                <ol><i class="bi bi-check-circle"></i> {{$t("scope-p-2-3")}}</ol>
                <!-- <ol><i class="bi bi-check-circle"></i> {{$t("call-intro-p-12-list-4")}}</ol> -->
            </ul>
            <p>{{$t("scope-p-3")}}</p><br/>

            <p>{{$t("scope-p-4")}} </p>
            <ul>
              <li><i class="bi bi-check-circle"></i>  {{$t("scope-list-1")}}</li>
              <li><i class="bi bi-check-circle"></i> {{$t("scope-list-2")}}</li>
            </ul>
            <p v-html="$t('bold-text-category-content')"></p>
             </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>