<template>
    <section id="requirements" class="events">
        <div class="container" data-aos="fade-up">
            <div class="row">
                <div class="col-md-12 d-flex align-items-stretch  order-2 order-lg-1 content">
                    <div class="card">
                        <div class="card-body">
                             <!-- <p><b>{{$t("bold-text-category-content")}}</b></p> -->

                            <ul>
                                <li><i class="bi bi-check-circle"></i> {{$t("category-list-1")}}</li>
                                <!-- <li><i class="bi bi-check-circle"></i> {{$t("category-list-2")}}</li> -->
                                <li><i class="bi bi-check-circle"></i> {{$t("category-list-3")}}</li>
                                <li><i class="bi bi-check-circle"></i> {{$t("category-list-3-b")}}</li>
                                <li><i class="bi bi-check-circle"></i> {{$t("category-list-4-main")}}</li>
                                <ul>
                                    <ol><i class="bi bi-check-square"></i> {{$t("category-list-8")}}</ol>
                                    <ol><i class="bi bi-check-square"></i> {{$t("category-list-4")}}</ol>
                                    <ol><i class="bi bi-check-square"></i> {{$t("category-list-7")}}</ol>
                                </ul>
                                <!-- <li><i class="bi bi-check-circle"></i> {{$t("category-list-5")}}</li> -->
                                <!-- <li><i class="bi bi-check-circle"></i> {{$t("category-list-6")}}</li> -->
                                <!-- <li><i class="bi bi-check-circle"></i> </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>