<template>
  <div class="about">
    <div class="breadcrumbs" data-aos="fade-in">
      <div class="container">
        <h2>{{$t("about-the-awards")}}</h2>
      </div>
    </div>

    <intro-content/>
    <scope/>
    <scope-content  class="mt-5"/>
    <!-- <catergories/>
    <category-content class="mt-5"/> -->

    <!-- <apply/>
    <apply-content class="mt-5"/> -->

    <!-- <criterion/>
    <criteria-content  class="mt-5"/> -->
    <requirements  id="requirements"/>
    <requirement-content/>


  </div>
</template>
<script>
// import ApplyContent from '../components/about/ApplyContent.vue'
// import CategoryContent from '../components/about/CategoryContent.vue'
// import CriteriaContent from '../components/about/CriteriaContent.vue'
import IntroContent from '../components/about/IntroContent.vue'
import RequirementContent from '../components/about/RequirementContent.vue'
import ScopeContent from '../components/about/ScopeContent.vue'
// import Apply from '../components/ctas/Apply.vue'
// import Catergories from '../components/ctas/Catergories.vue'
// import Criterion from '../components/ctas/Criterion.vue'
import Requirements from '../components/ctas/Requirements.vue'
import Scope from '../components/ctas/Scope.vue'
export default {
  components: {Scope, IntroContent, ScopeContent, Requirements, RequirementContent},
}
    
</script>
